<script>
export default {
  name: "FooterComponent"
}
</script>

<template>
<!---- TODO: make sure the footer doesn't extend the page when there is clearly space ---->
<footer class="footer mt-auto py-3">
  <div class="container">
    <div class="row">
      <p class="col-md-4 mb-0 text-body-secondary">©2024 Soled.io, All rights reserved.</p>
      <p class="mb-0 text-body-secondary">Contact us: <a href="mailto:marketing@soled.io">marketing@soled.io</a></p>
    </div>
  </div>
</footer>
</template>

<style scoped>

</style>